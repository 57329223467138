import { HttpClient } from '@angular/common/http';
import { Inject } from '@angular/core';
import { environment } from 'src/environments/environment';

export abstract class BaseService {
  url: string = '';
  http: HttpClient;

  protected constructor(
    public urlBase: string,
    @Inject(HttpClient) http: HttpClient,
  ) {
    this.url = `${environment.serviceUrl}/v1/${this.urlBase}`;
    this.http = http;
  }

}
