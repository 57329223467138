import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { catchError, map, Observable, of } from 'rxjs';
import { GlobalVariables } from '../model/global-variables';
import { AuthenticateService } from '../service/authenticate/authenticate.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import {BehaviorService} from "../service/behavior.service";
import {AppSettings} from "../../app.settings";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  public globalVariables = new GlobalVariables();

  constructor(
    private _authService: AuthenticateService,
    private appSettings: AppSettings,
    private router: Router,
    private snackBar: MatSnackBar,
    private _behaviorService: BehaviorService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.globalVariables.Token;

    if (request.url.includes('notification') && request.method == 'POST' || request.headers.get('Skip-interceptor') == 'true') {
      return next.handle(request)
        .pipe(
          map((event: HttpEvent<any>) => {
            return event;
          })
        );
    }
    else {
      const newRequest = request.clone({
        headers: request.headers.set('Authorization', token ? `Bearer ${token}` : '')
      });

      setTimeout(() => {

        if (['POST', 'PUT'].includes(request.method) && !request.headers.get('Skip-loading')) {
          this._behaviorService.messageWaitAMomement.next('Aguarde um momento, por favor');
          this._behaviorService.waitAMoment.next(true);
        }
      });

      return next.handle(newRequest)
        .pipe(
          map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              this._behaviorService.waitAMoment.next(false);
            }
            return event;
          }), catchError((error: HttpErrorResponse) => {
            this._behaviorService.waitAMoment.next(false);
            if (error.status == 401) {
              this._authService.logout();
            }
            if (error.status == 500) {
              // this.router.navigate(['/']);
            }
            if (error.status == 404) {
              this.snackBar.open('Nenhum registro foi encontrado.')._dismissAfter(5000)
            }
            return of(new HttpResponse({
              body: {
                success: false,
                data: error?.error,
                message: error?.error?.error || error?.message,
              }
            }));
          })
        );
    }
  }
}
