import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ModalData {
  title: string;
  detail: string
  message: string;
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
})
export class DialogComponent implements OnInit {

  @Output() confirm = new EventEmitter();
  public title: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalData
  ) { }

  ngOnInit() { }

}
