<div class="modal  is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title is-danger">Aviso</p>
        <button class="delete" (click)="close()"></button>
      </header>
      <section class="modal-card-body">
          <p style="color:red">{{data.error}}</p>
      </section>
      <footer class="modal-card-foot">        
        <button class="button" (click)="close()">Ok</button>
      </footer>
    </div>
  </div>