import { Component } from "@angular/core";
import {BehaviorService} from "../../service/behavior.service";


export interface DialogData {
  error: any[];
}

@Component({
  selector: 'app-dialog-error',
  templateUrl: 'dialog-error.html',
})
export class DialogErrorComponent {

  public data: any = {};
  constructor(private behaviorService: BehaviorService) {
      this.behaviorService.dataError.subscribe( value => {
        this.data = value;
    });
  }

  close(): void {
    this.behaviorService.showErrorMessage.next(false);
  }

}
