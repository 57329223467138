<h2 mat-dialog-title>{{data.title}}</h2>

<mat-dialog-content class="mat-typography mb-4">
  <h3 style="font-weight: bold">{{data.detail}}</h3>
  <div *ngFor="let message of data.message">
    <span>{{message}}</span><br>
  </div>

</mat-dialog-content>
<mat-dialog-actions>
  <button [mat-dialog-close]="true" mat-button cdkFocusInitial>Confirmar</button>
  <button mat-button mat-dialog-close>Cancelar</button>
</mat-dialog-actions>
