import {Component, ViewChild} from '@angular/core';
import {AppSettings} from './app.settings';
import {Settings} from './app.settings.model';
import {environment} from "../environments/environment";
import {BehaviorService} from "./shared/service/behavior.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public settings: Settings;
  isHomolog: boolean = environment.isHomolog;

  constructor(public appSettings: AppSettings,
              private behaviorService: BehaviorService) {
    this.settings = this.appSettings.settings;
    this.behaviorService.waitAMoment.subscribe((res) => this.settings.loadingSpinner = res);
  }

  ngOnInit() {
  }
}
