<header class="header" [class.over]="showInfoContent">
    <mat-toolbar class="top-toolbar" [class.mat-elevation-z2]="settings.fixedHeader">
        <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
            <div fxLayout="row" fxLayoutAlign="center center">
                <a routerLink="/" fxLayout="row" fxLayoutAlign="start center" class="logo" ngClass.xs="mobile" (click)="closeSubMenus()">
                  <img src="assets/img/logo-transparent.png" style="width: 34px" class="animated-logo"> &nbsp; Specialty
                </a>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" class="w-100">
              <div class="menu-icon" *ngIf="settings.menu == 'vertical'"
                   [class.active]="showSidenav" (click)="sidenav.toggle();"
                   [matTooltip]="showSidenav ? 'Recolher menu' : 'Expandir Menu'">
                <span></span><span></span><span></span>
              </div>
                <button *ngIf="settings.menu == 'vertical'" mat-icon-button (click)="settings.sidenavIsPinned = !settings.sidenavIsPinned" fxShow="false" fxShow.gt-xs>
                    <svg class="pin">
                        <path *ngIf="!settings.sidenavIsPinned" d="M16,12V4H17V2H7V4H8V12L6,14V16H11.2V22H12.8V16H18V14L16,12Z" />
                        <path *ngIf="settings.sidenavIsPinned" d="M2,5.27L3.28,4L20,20.72L18.73,22L12.8,16.07V22H11.2V16H6V14L8,12V11.27L2,5.27M16,12L18,14V16H17.82L8,6.18V4H7V2H17V4H16V12Z" />
                    </svg>
                </button>
                <app-favorites fxShow="false" fxShow.gt-sm *ngIf="showLayoutSettings"></app-favorites>
            </div>
            <div fxLayout="row" fxLayoutAlign="end center">
                <app-applications></app-applications>
                <app-fullscreen></app-fullscreen>
                <app-user-menu></app-user-menu>
            </div>
        </div>
    </mat-toolbar>
</header>

<mat-drawer-container class="page-wrapper">

    <mat-drawer #sidenav *ngIf="settings.menu == 'vertical'" [opened]="settings.sidenavIsOpened" [mode]="(settings.sidenavIsPinned) ? 'side' : 'over'" (openedStart)="showSidenav = !showSidenav" (closedStart)="showSidenav = !showSidenav" class="sidenav mat-elevation-z6" autoFocus="false">
        <mat-toolbar [class.homolog]="isHomolog" *ngIf="settings.fixedSidenav" class="top-toolbar">
            <a routerLink="/" fxLayout="row" fxLayoutAlign="start center" class="logo">
                <img src="assets/img/logo.png" alt="logo">
            </a>
        </mat-toolbar>
        <app-sidenav></app-sidenav>
    </mat-drawer>

    <mat-drawer-content #mainContent (scroll)="onMainContentScroll($event)">

        <div class="main-content-inner">
            <router-outlet></router-outlet>
        </div>

        <div fxLayout="row" fxLayoutAlign="center center" class="options-icon" (click)="options.toggle()" *ngIf="showLayoutSettings">
            <mat-icon>settings</mat-icon>
        </div>
        <div #backToTop fxLayout="row" fxLayoutAlign="center center" class="back-to-top" (click)="scrollToTop()">
            <mat-icon>arrow_upward</mat-icon>
        </div>
    </mat-drawer-content>

    <mat-drawer #options position="end" class="options">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="op-header mat-elevation-z1">
            <h2>Layout</h2>
            <button mat-icon-button (click)="options.toggle()" class="ml-2">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <ng-scrollbar pointerEventsMethod="scrollbar">
            <div fxLayout="column" class="control">
                <h4>Menu</h4>
                <mat-radio-group [(ngModel)]="menuTypeOption" (change)="chooseMenuType()">
                    <mat-radio-button *ngFor="let menuType of menuTypes" [value]="menuType.value">{{menuType.label}}</mat-radio-button>
                </mat-radio-group>
            </div>
            <div fxLayout="column" class="control">
                <h4>Temas</h4>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="skin-primary indigo-light" (click)="changeTheme('indigo-light')"><div class="skin-secondary"></div></div>
                    <div class="skin-primary teal-light" (click)="changeTheme('teal-light')"><div class="skin-secondary"></div></div>
                    <div class="skin-primary red-light" (click)="changeTheme('red-light')"><div class="skin-secondary"></div></div>
                    <div class="skin-primary gray-light" (click)="changeTheme('gray-light')"><div class="skin-secondary"></div></div>
                </div>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="skin-primary blue-dark" (click)="changeTheme('blue-dark')"><div class="skin-secondary"></div></div>
                    <div class="skin-primary green-dark" (click)="changeTheme('green-dark')"><div class="skin-secondary"></div></div>
                    <div class="skin-primary pink-dark" (click)="changeTheme('pink-dark')"><div class="skin-secondary"></div></div>
                    <div class="skin-primary gray-dark" (click)="changeTheme('gray-dark')"><div class="skin-secondary"></div></div>
                </div>
            </div>
          <div fxLayout="column" class="control">
            <h4>Menu Fixo</h4>
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <span>Expandir</span>
              <mat-slide-toggle [checked]="settings.sidenavIsOpened" (change)="settings.sidenavIsOpened = !settings.sidenavIsOpened" labelPosition="before"></mat-slide-toggle>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <span>Fixo</span>
              <mat-slide-toggle [checked]="settings.sidenavIsPinned" (change)="settings.sidenavIsPinned = !settings.sidenavIsPinned" labelPosition="before"></mat-slide-toggle>
            </div>
          </div>
        </ng-scrollbar>
    </mat-drawer>

</mat-drawer-container>
