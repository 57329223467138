<div class="modal  is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title is-danger">Dados alterados com sucesso!</p>
        <button class="delete" (click)="close()"></button>
      </header>
      <section class="modal-card-body">
          <p style="color:red">{{data.error}}</p>
      </section>
      <footer class="modal-card-foot">        
        <!-- <button class="button" matTooltip="Continuar na págia" [matTooltipPosition]="'above'" (click)="close()">Ok</button> -->
        <button class="button" matTooltip="Voltar a tela anterior" [matTooltipPosition]= "'above'" (click)="closeBack()">OK</button>
      </footer>
    </div>
  </div>