import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import {BehaviorService} from "../../service/behavior.service";

export interface DialogData {
  error: any[];
}

@Component({
  selector: 'app-dialog-error-reload',
  templateUrl: 'dialog-error-reload.html',
})
export class DialogErrorReloadComponent {
  @Input() route: string = '#';
  public data: any = {};
  constructor(
    private behaviorService: BehaviorService,
    private router: Router) {
      this.behaviorService.dataError.subscribe( value => {
        this.data = value;
    });
  }

  close(): void {
    this.behaviorService.showErrorMessageReload.next(false);
    location.reload();
  }

  closeBack(): void {
    this.behaviorService.showErrorMessageReload.next(false);
    this.router.navigate([this.route]);
  }
}
