import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PaginationPipe} from './pagination/pagination.pipe';
import { ProfilePicturePipe } from './profilePicture/profilePicture.pipe';
import { ChatPersonSearchPipe } from './search/chat-person-search.pipe';
import { UserSearchPipe } from './search/user-search.pipe';
import { TruncatePipe } from './truncate/truncate.pipe';
import { MailSearchPipe } from './search/mail-search.pipe';
import {FormatDocument} from "../../shared/utils/format-document.pipe";
import {UrlSafePipe} from "./urlsafe/url.safe.pipe";

@NgModule({
    imports: [
        CommonModule
    ],
  declarations: [
    PaginationPipe,
    ProfilePicturePipe,
    ChatPersonSearchPipe,
    UserSearchPipe,
    TruncatePipe,
    MailSearchPipe,
    UrlSafePipe,
    FormatDocument
  ],
  exports: [
    PaginationPipe,
    ProfilePicturePipe,
    ChatPersonSearchPipe,
    UserSearchPipe,
    TruncatePipe,
    MailSearchPipe,
    UrlSafePipe,
    FormatDocument
  ]
})
export class PipesModule { }
