import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {GlobalVariables} from "../model/global-variables";

@Injectable({
  providedIn: 'root'
})
export class PagesGuard implements CanActivate {

  public globalVars = new GlobalVariables();
  constructor() { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = this.globalVars.Token;
    const selectedCompanyId = this.globalVars.UserCompanySelected;

    // if (session && token && selectedCompanyId && applications) {
    if (token && selectedCompanyId) {
        return true;
    }
    else {
      let query = '?returnApp=S-Flow'
      if (state.url.includes('/form/fill/edit/')) {
        query += `&redirect=${state.url}`;
      }
      window.location.href = `${environment.sso.web + '/login'}${query}`;
      return false;
    }
  }

}
